<template>
  <ValidationObserver
    ref="observer1"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      v-if="$can('create', 'catalog')"
      class="p-0"
      method="POST"
      novalidate
      autocomplete="off"
      @submit.prevent="handleSubmit(saveAttribute)"
    >
      <b-select-validation
        v-model="newAttribute.type"
        :label="$t('type')"
        class="mb-2"
        :vid="`type`"
        rules="required"
        :expanded="true"
        :disabled="!!newAttribute.id || disableType"
      >
        <template v-for="opt in types">
          <option
            v-if="forceAdd || opt.new_val || newAttribute.id"
            :key="opt.id"
            :value="opt.id"
          >
            {{ opt.label }}
          </option>
        </template>
      </b-select-validation>
      <div
        v-if="hasTrans(newAttribute.type)"
        class="columns is-mobile is-multiline is-variable"
      >
        <div
          v-for="(lang, index) in languages"
          :key="`language_${lang.iso}`"
          class="column is-6"
        >
          <b-input-validation
            v-model="newAttribute.trans[lang.iso]"
            :placeholder="placeholders.attribute[selectedType.key]"
            rules="required"
            :vid="`value_${index}`"
            :label="lang.label"
          />
        </div>
      </div>
      <b-input-validation
        v-else
        v-model="newAttribute.value"
        :placeholder="placeholders.number['2']"
        rules="required"
        :vid="`value`"
        :label="$tc('value', 1)"
        input-type="number"
      />

      <div class="mt-2">
        <b-button
          type="is-primary"
          :disabled="save_attribute_loading"
          :loading="save_attribute_loading"
          class="mr-3"
          tag="input"
          native-type="submit"
          :value="$t('save')"
        >
          {{ $t("save") }}
        </b-button>
        <b-button
          :disabled="save_attribute_loading"
          @click="$emit('cancel')"
        >
          {{ $t("cancel") }}
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    editObj: {
      type: Object,
      default: () => {}
    },
    types: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    disableType: {
      type: Boolean,
      default: false
    },
    forceAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      save_attribute_loading: false,
      newAttribute: {
        type: null,
        value: null,
        trans: {}
      }
    };
  },
  computed: {
    selectedType() {
      let type = this.types.find(o => o.id == this.newAttribute.type);
      return type || {};
    }
  },
  watch: {
    editObj(newVal) {
      if (newVal.id) {
        this.newAttribute = newVal;
      } else {
        this.clearInputs();
      }
    }
  },
  created() {
    this.newAttribute.type = this.editObj.type;
    if (this.editObj.typedValue) {
      if (this.hasTrans(this.newAttribute.type) && this.languages[0])
        this.newAttribute.trans[
          this.languages[0].iso
        ] = this.editObj.typedValue;
      else this.newAttribute.value = this.editObj.typedValue;
    }
  },
  methods: {
    hasTrans(id) {
      if (!id) return false;
      let type = this.types.find(t => t.id == id);
      return type && type.trans;
    },
    clearInputs() {
      this.newAttribute = {
        type: null,
        value: null,
        trans: {}
      };
      this.$refs.observer1.reset();
    },
    saveAttribute() {
      this.save_attribute_loading = true;
      let item = this.newAttribute;

      let url = `catalog/attributes${item.id ? "/" + item.id : ""}`,
        method = item.id ? "PUT" : "POST",
        data = {};

      if (this.hasTrans(item.type)) {
        data.translations = [];

        for (const [key, value] of Object.entries(item.trans)) {
          data.translations.push({
            iso: key,
            value: value
          });
        }
      } else {
        data.value = item.value;
      }

      if (!item.id) {
        data.id = item.type;
      }

      this.$axios({
        method: method,
        url: url,
        data: data
      })
        .then(({ data }) => {
          if (item.id) {
            data.id = item.id;
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else {
            data.type_id = item.type;
            this.$root.notify(this.$t("successfully_saved"), "is-success");
          }
          this.$emit("created", data);
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.save_attribute_loading = false));
    }
  }
};
</script>

